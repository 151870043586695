import "./App.css";
// import bloomberg from "./bloomberg.svg";
import crunchbase from "./crunchbase.svg";
import email from "./email.svg";
import linkedin from "./linkedin.svg";
import logo from "./logo.svg";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img
                    src={logo}
                    className="App-logo"
                    alt="nompeer NomPeer Legal Tech Joshua Mummert Stern Steve Martin Philadelphia PA"
                />
                <p id="tagline">coming soon</p>
                <div id="socialMediaIcons">
                    <a
                        className="App-link"
                        href="https://www.linkedin.com/company/nompeer/about/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            id="linkedin"
                            src={linkedin}
                            className="social-media-icon"
                            alt="nompeer NomPeer Legal Tech Joshua Mummert Stern Steve Martin Philadelphia PA"
                        />
                    </a>
                    {/* <a
                        className="App-link"
                        href="https://www.bloomberg.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            id="bloomberg"
                            src={bloomberg}
                            className="social-media-icon"
                            alt="nompeer NomPeer Legal Tech Joshua Mummert Stern Steve Martin Philadelphia PA"
                        />
                    </a> */}
                    <a
                        className="App-link"
                        href="mailto:steve@nompeer.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            id="bloomberg"
                            src={email}
                            className="social-media-icon"
                            alt="nompeer NomPeer Legal Tech Joshua Mummert Stern Steve Martin Philadelphia PA"
                        />
                    </a>

                    <a
                        className="App-link"
                        href="https://www.crunchbase.com/organization/nompeer"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            id="crunchbase"
                            src={crunchbase}
                            className="social-media-icon"
                            alt="nompeer NomPeer Legal Tech Joshua Mummert Stern Steve Martin Philadelphia PA"
                        />
                    </a>
                </div>
            </header>
        </div>
    );
}

export default App;
